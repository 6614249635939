import { INLINES, Node } from "@contentful/rich-text-types"
import React, { ReactNode } from "react"
import styled from "styled-components"
import { resolveIsEmail } from "../../../contentful/options/rich-text"
import { generateSources } from "../../../helpers/images"
import RichText from "../../../resolvers/rich-text/rich-text.resolver"
import { AppFunctionComponent, HeroSectionQueryResult } from "../../../types"
import { TextLink } from "../../../ui/components/link/link.component"
import { PrimaryHeading } from "../../common/heading.component"
import Hero, {
  HeroDesktopLayout,
  HeroHeading,
  HeroImage,
  HeroImageBehavior,
  HeroText,
} from "../../common/sections/hero-section-refactored.component"

const HeroContainer = styled(Hero)`
  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    --text-max-width: 400px;
  }
`

const NoBreakLink = styled(TextLink)`
  white-space: nowrap;
`

const MediaHero: AppFunctionComponent<HeroSectionQueryResult> = ({
  content,
}) => {
  const { mobileImage, tabletImage, desktopImage, primaryTitle, text } = content

  return (
    <HeroContainer
      desktopLayout={HeroDesktopLayout.SeparatedTexts}
      imageBehavior={HeroImageBehavior.Cover}
    >
      <HeroHeading>
        <PrimaryHeading renderAs={"h1"}>{primaryTitle}</PrimaryHeading>
      </HeroHeading>
      <HeroText>
        {text && (
          <RichText
            content={text}
            options={{
              [INLINES.HYPERLINK]: (linkNode: Node, children: ReactNode) => (
                <NoBreakLink to={resolveIsEmail(linkNode.data.uri)}>
                  {children}
                </NoBreakLink>
              ),
            }}
          />
        )}
      </HeroText>
      <HeroImage
        image={generateSources([
          [desktopImage],
          [tabletImage, 1140],
          [mobileImage, 500],
        ])}
        loading="eager"
      />
    </HeroContainer>
  )
}

export default MediaHero
