import React from "react"
import { AppFunctionComponent } from "../../../types"
import {
  PressReleaseText,
  PressReleaseWrapper,
  ResultsGrid,
  PressReleaseImage,
  PressReleaseSection,
} from "./press-release-results.styled"
import { PostDate } from "../../common/general-styles.component"
import { QuinaryHeading, PrimaryHeading } from "../../common/heading.component"
import { NeutralRawLink } from "../../../ui/components/link/link.component"
import { PressReleaseResultsProps } from "./press-release-results.types"
import Pagination from "../../common/pagination.component"
import { Path } from "../../../navigation/constants/routes"
import useTranslationHook from "../../../hooks/use-translation.hook"

const PressReleaseResults: AppFunctionComponent<PressReleaseResultsProps> = ({
  pressReleases,
  pagination,
}) => {
  const { translate } = useTranslationHook()
  return (
    <>
      <PressReleaseSection>
        <PrimaryHeading renderAs={"h2"}>
          {translate("press-releases")}
        </PrimaryHeading>
        <ResultsGrid>
          {pressReleases.map((pressRelease, index) => {
            const {
              featuredImage: { gatsbyImageData },
              title,
              slug,
              originalPublishDate,
            } = pressRelease
            return (
              <PressReleaseWrapper key={index}>
                <NeutralRawLink to={`${Path.Media}${slug}`}>
                  <PressReleaseImage image={gatsbyImageData} />
                  <PressReleaseText>
                    <PostDate>{originalPublishDate}</PostDate>
                    <QuinaryHeading renderAs={"h3"}>{title}</QuinaryHeading>
                  </PressReleaseText>
                </NeutralRawLink>
              </PressReleaseWrapper>
            )
          })}
        </ResultsGrid>
      </PressReleaseSection>
      {pagination && (
        <Pagination
          current={pagination.currentPage!}
          total={pagination.total!}
          rootUrl={Path.Media.toString()}
        />
      )}
    </>
  )
}

export default PressReleaseResults
