import styled from "styled-components"
import { PostDate } from "../../common/general-styles.component"
import { PrimaryHeading, QuinaryHeading } from "../../common/heading.component"
import Section from "../../common/sections/section.component"
import { Image } from "../../common/image.component"

export const PressReleaseSection = styled(Section)`
  ${PrimaryHeading} {
    justify-self: center;
    margin-top: 0;
    @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
      margin-bottom: 67px;
    }
  }
`

export const ResultsGrid = styled.div`
  display: grid;
  align-items: stretch;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-auto-rows: auto;

  @media (min-width: ${({ theme }) => theme.breakpoint.tabletHorizontal}px) {
    grid-template-columns: repeat(3, minmax(260px, 1fr));
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}px) {
    grid-gap: 50px 30px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}px) {
    grid-gap: 70px 40px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktopMedium}px) {
    grid-gap: 90px 40px;
  }
`

export const PressReleaseWrapper = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.size.borderRadius};
  :hover {
    transition: all 0.3s linear;
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.textInverted};
    box-shadow: 0 20px 40px 0 ${({ theme }) => theme.color.commerceShadow};
  }
`

export const PressReleaseImage = styled(Image)`
  height: 0;
  width: 100%;
  padding-bottom: 80%;

  img {
    object-fit: cover;
  }
`

export const PressReleaseText = styled.div`
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.background};

  ${QuinaryHeading} {
    margin-top: 0;
    margin-bottom: 0;
    min-height: 3em;
  }

  ${PostDate} {
    margin-top: 0;
    margin-bottom: 10px;
  }

  p {
    flex: 1;
  }
`
